@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

:root {
  --bg-color: #f0f4f8;
  --text-color: #333;
  --header-color: #ff6347;
  --card-bg-color: #ffffff;
  --card-text-color: #2c3e50;
  --card-shadow: rgba(0, 0, 0, 0.1);
  --timeline-color: #ff6347;
}

.dark {
  --bg-color: #282a36;
  --text-color: #f8f8f2;
  --header-color: #ff79c6;
  --card-bg-color: #44475a;
  --card-text-color: #f8f8f2;
  --card-shadow: rgba(0, 0, 0, 0.3);
  --timeline-color: #ff79c6;
}

.education-section {
  background-color: var(--bg-color);
  color: var(--text-color);
  padding: 80px 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  transition: background-color 0.3s, color 0.3s;
}

.education-section h2 {
  font-size: 2.5rem;
  color: var(--header-color);
  text-align: center;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.education-timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.education-timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: var(--timeline-color);
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  border-radius: 3px;
}

.timeline-item {
  padding: 10px 40px;
  position: relative;
  width: 50%;
  box-sizing: border-box;
}

.timeline-item:nth-child(odd) {
  left: 0;
}

.timeline-item:nth-child(even) {
  left: 50%;
}

.timeline-content {
  padding: 20px 30px;
  background-color: var(--card-bg-color);
  position: relative;
  border-radius: 15px;
  box-shadow: 0 4px 20px var(--card-shadow);
}

.timeline-icon {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: var(--timeline-color);
  top: 50%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 1;
}

.timeline-item:nth-child(odd) .timeline-icon {
  right: -60px;
}

.timeline-item:nth-child(even) .timeline-icon {
  left: -60px;
}

.education-card {
  margin-bottom: 20px;
}

.education-card h3 {
  font-size: 1.4rem;
  color: var(--header-color);
  margin: 0 0 10px 0;
}

.education-card h4 {
  font-size: 1.2rem;
  margin: 10px 0;
  color: var(--card-text-color);
}

.duration-location, .activities, .grade {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--card-text-color);
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.activities, .grade {
  font-style: italic;
}

@media (max-width: 768px) {
  .education-timeline::after {
    left: 31px;
  }
  
  .timeline-item {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }
  
  .timeline-item:nth-child(even) {
    left: 0;
  }
  
  .timeline-icon {
    left: 10px !important;
  }
}