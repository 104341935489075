@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

:root {
  --bg-color: #f0f4f8;
  --text-color: #333;
  --header-color: #ff6347;
  --accent-color: #ff6347;
  --form-bg: #ffffff;
  --form-border: #e0e0e0;
  --button-hover: #ff6347;
  --footer-bg: #2c3e50;
  --footer-text: #ecf0f1;
}

.dark-mode {
  --bg-color: #1a202c;
  --text-color: #f8f8f2;
  --header-color: #ff79c6;
  --accent-color: #ff79c6;
  --form-bg: #2d3748;
  --form-border: #4a5568;
  --button-hover: #ff79c6;
  --footer-bg: #282a36;
  --footer-text: #f8f8f2;
}

.contact-footer {
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: 'Poppins', sans-serif;
  padding: 100px 0 0;
  transition: background-color 0.3s, color 0.3s;
}

.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.contact-footer h2 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 60px;
  color: var(--header-color);
  font-weight: 700;
}

.contact-content {
  display: flex;
  justify-content: space-between;
  gap: 60px;
}

.contact-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-form input,
.contact-form textarea {
  padding: 15px;
  border-radius: 8px;
  border: 2px solid var(--form-border);
  background-color: var(--form-bg);
  color: var(--text-color);
  font-size: 1rem;
  transition: all 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.3);
}

.contact-form textarea {
  min-height: 150px;
  resize: vertical;
}

.contact-form button {
  padding: 15px 30px;
  background-color: var(--accent-color);
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.contact-form button:hover {
  background-color: var(--button-hover);
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-info h3 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: var(--header-color);
  font-weight: 700;
}

.contact-info p {
  font-size: 1.1rem;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 1.6;
}

.social-links {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.social-links a {
  color: var(--accent-color);
  font-size: 1.8rem;
  transition: all 0.3s ease;
}

.social-links a:hover {
  color: var(--button-hover);
  transform: translateY(-3px);
}

.footer {
  margin-top: 100px;
  background-color: var(--footer-bg);
  color: var(--footer-text);
  padding: 40px 0;
}

  .built-with p {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-bottom: 10px;
  }
  
  .heart-icon {
    color: #e74c3c;
    animation: heartbeat 1.5s ease infinite;
  }
  
  .tech-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .tech-icon {
    font-size: 2rem;
    transition: all 0.3s ease;
  }
  
  .react-icon {
    color: #61dafb;
  }
  
  .tailwind-icon {
    color: #06b6d4;
  }
  
  .copyright {
    font-size: 0.9rem;
  }
  
  @keyframes heartbeat {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.1); }
  }