@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

:root {
  --bg-color: #f0f4f8;
  --text-color: #333;
  --header-color: #ff6347;
  --card-bg-color: #ffffff;
  --card-text-color: #2c3e50;
  --card-shadow: rgba(0, 0, 0, 0.1);
  --icon-color: #3498db;
  --achievement-bg: #e74c3c;
  --achievement-text: #ffffff;
  --expand-btn-color: #3498db;
}

.dark-mode {
  --bg-color: #1a202c;
  --text-color: #f8f8f2;
  --header-color: #ff79c6;
  --card-bg-color: #2d3748;
  --card-text-color: #f8f8f2;
  --card-shadow: rgba(0, 0, 0, 0.3);
  --icon-color: #8be9fd;
  --achievement-bg: #ff5555;
  --achievement-text: #f8f8f2;
  --expand-btn-color: #8be9fd;
}

.works-section {
  background-color: var(--bg-color);
  color: var(--text-color);
  padding: 80px 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  transition: background-color 0.3s, color 0.3s;
}

.works-section h2 {
  font-size: 2.5rem;
  color: var(--header-color);
  text-align: center;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  width: 100%;
  max-width: 1200px;
}

.project-card {
  background-color: var(--card-bg-color);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 20px var(--card-shadow);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
}

.card-content {
  padding: 30px;
}

.project-card h3 {
  font-size: 1.4rem;
  color: var(--header-color);
  margin: 0 0 15px 0;
}

.description {
  font-size: 1rem;
  color: var(--card-text-color);
  overflow: hidden;
  position: relative;
}

.description p {
  margin: 0;
}

.achievement {
  background-color: var(--achievement-bg);
  color: var(--achievement-text);
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.expand-btn {
  background: none;
  border: none;
  color: var(--expand-btn-color);
  cursor: pointer;
  font-size: 1.2rem;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  transition: color 0.3s ease;
}

.expand-btn:hover {
  color: var(--header-color);
}

.project-links {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  padding: 15px 30px;
  background-color: rgba(0, 0, 0, 0.05);
}

.icon-link {
  color: var(--icon-color);
  font-size: 1.5rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.icon-link:hover {
  color: var(--header-color);
  transform: scale(1.1);
}

.icon-link.github:hover {
  color: #6e5494;
}

.icon-link.website:hover {
  color: #00a0d2;
}

@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: 1fr;
  }
}