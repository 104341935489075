@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

:root {
  --bg-color: #ffffff;
  --text-color: #333;
  --header-color: #ff6347;
  --skill-bg-color: #f0f0f0;
  --skill-text-color: #333;
  --skill-hover-bg-color: #ff6347;
  --skill-hover-text-color: #ffffff;
}

.dark-mode {
  --bg-color: #282a36;
  --text-color: #f8f8f2;
  --header-color: #ff79c6;
  --skill-bg-color: #44475a;
  --skill-text-color: #f8f8f2;
  --skill-hover-bg-color: #ff79c6;
  --skill-hover-text-color: #282a36;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s;
}

.about-section {
  padding: 80px 20px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-color);
  color: var(--text-color);
}

.about-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.about-content h2 {
  font-size: 2.5rem;
  color: var(--header-color);
  margin-bottom: 30px;
}

.about-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.skills-container {
  margin-top: 40px;
}

.skills-container h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: var(--header-color);
}

.skills-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.skill-item {
  background-color: var(--skill-bg-color);
  padding: 8px 15px;
  border-radius: 20px;
  font-size: 0.9em;
  color: var(--skill-text-color);
  transition: all 0.2s ease;
  cursor: pointer;
}

.skill-item:hover {
  background-color: var(--skill-hover-bg-color);
  color: var(--skill-hover-text-color);
}

@media (max-width: 768px) {
  .about-content h2 {
    font-size: 2rem;
  }

  .about-content p {
    font-size: 1rem;
  }
}
