@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Roboto:wght@300;400;700&family=Montserrat:wght@700&display=swap');

:root {
    --bg-color: #f5f5f5;
    --text-color: #333;
    --subtitle-color:#333;
    --header-color:#ff6347;
    --accent-color: #ff6347;
    --terminal-bg: #1e1e1e;
    --terminal-header-bg: #323232;
    --terminal-text: #f8f8f2;
    --terminal-keyword: #ff79c6;
    --terminal-function: #50fa7b;
    --terminal-string: #f1fa8c;
    --terminal-number: #bd93f9;
    --terminal-comment: #6272a4;
    --background-text-stroke: rgba(0, 0, 0, 0.1);
  }
  
  .dark-mode {
    --bg-color: #282a36;
    --text-color: #f8f8f2;
    --subtitle-color:#ffffff;
    --accent-color: #ff79c6;
    --header-color:var(--accent-color);
    --terminal-bg: #282a36;
    --terminal-header-bg: #44475a;
    --background-text-stroke: rgba(255, 255, 255, 0.1);
  }
  
  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    background-color: var(--bg-color);
    color: var(--text-color);
    transition: background-color 0.3s, color 0.3s;
  }

.landing-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.background-text-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 1;
}

.background-text {
    display: flex;
    font-family: 'Montserrat', sans-serif;
    font-size: 15vw;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke: 1px var(--background-text-stroke);
    text-stroke: 1px var(--background-text-stroke);
    white-space: nowrap;
    animation: moveLeft 120s linear infinite;
  }

.background-word {
  margin-right: 2vw;
}

@keyframes moveLeft {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 80vh;
  z-index: 2;
  padding: 2rem;
  max-width: 800px;
  width: 100%;
}

.header {
  text-align: center;
  margin-bottom: 2rem;
}

.header h2 {
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 0;
}

.header h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 3.5rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: var(--header-color);
}

.subtitle {
  font-size: 1.2rem;
  color: var(--subtitle-color);
}

.code-terminal {
    width: 100%;
    max-width: 600px;
    height: 300px;
    background-color: var(--terminal-bg);
    border-radius: 8px;
    overflow: hidden;
    font-family: 'Roboto Mono', monospace;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  }
  
  .terminal-header {
    background-color: var(--terminal-header-bg);
    padding: 8px 12px;
    display: flex;
    align-items: center;
  }
  
  .window-controls {
    display: flex;
    gap: 8px;
  }
  
  .window-control {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  
  .window-control.close { background-color: #ff5f56; }
  .window-control.minimize { background-color: #ffbd2e; }
  .window-control.fullscreen { background-color: #27c93f; }
  
  .terminal-body {
    padding: 20px;
    color: var(--terminal-text);
    font-size: 14px;
    line-height: 1.5;
    height: calc(100% - 36px);
    overflow-y: auto;
    margin: 0;
  }
  
  .terminal-body code {
    white-space: pre-wrap;
    font-family: 'Roboto Mono', monospace;
  }
  
  .terminal-body .keyword { color: var(--terminal-keyword); }
  .terminal-body .function { color: var(--terminal-function); }
  .terminal-body .string { color: var(--terminal-string); }
  .terminal-body .number { color: var(--terminal-number); }
  .terminal-body .comment { color: var(--terminal-comment); }
  
  
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    padding: 1rem 2rem;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .dark-mode .footer {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .dark-mode-toggle {
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    transition: color 0.3s ease;
  }
  
  .dark-mode-toggle:hover {
    color: var(--accent-color);
  }
  
  .learn-more {
    text-align: center;
    flex-grow: 1;
  }
  
  .learn-more h3 {
    font-size: 1.5rem;
    margin-bottom: 0.25rem;
  }
  
  .learn-more p {
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-top: 0;
  }
  
  .scroll-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .scroll-arrow svg {
    font-size: 2rem;
    color: var(--accent-color);
    animation: bounce 2s infinite;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
    40% { transform: translateY(-20px); }
    60% { transform: translateY(-10px); }
  }
  
  @media (max-width: 768px) {
    .footer {
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;
    }
    
    .dark-mode-toggle {
      order: -1;
      align-self: flex-start;
    }
    
    .scroll-arrow {
      align-self: flex-end;
    }
  }
  